/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    pre: "pre",
    code: "code",
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    strong: "strong",
    em: "em",
    h3: "h3",
    ul: "ul",
    li: "li",
    hr: "hr"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<div>\n\t<br />\n\t<Stack alignItems='center' justifyContent='center'>\n\t\t<Stack.Item className='p-r-5'>\n\t\t\t<Tooltip open text=\"This is a really long tooltip that stretches over many lines\">\n\t\t\t\t<Link href=\"https://dribbble.com/servicetitan\" primary>\n\t\t\t\t\tA Link\n\t\t\t\t</Link>\n\t\t\t</Tooltip>\n\t\t</Stack.Item>\n\t\t<Stack.Item>\n\t\t\t<Tooltip open text=\"This is a tooltip\" direction=\"r\">\n\t\t\t\t<Button iconName='help' />\n\t\t\t</Tooltip>\n\t\t</Stack.Item>\n\t</Stack>\n</div>\n")), "\n", React.createElement(_components.h1, {
    id: "content",
    style: {
      position: "relative"
    }
  }, "Content", React.createElement(_components.a, {
    href: "#content",
    "aria-label": "content permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Tooltip content is designed to be concise. Tooltips only support basic text and cannot support features like ", React.createElement(_components.strong, null, "bold"), ", ", React.createElement(_components.em, null, "italics"), ", links, paragraphs, or images. Basic symbols and emojis (e.g. ⌘, ©, 👋) are allowed."), "\n", React.createElement(_components.p, null, "A tooltip's max width is 268px (252px/18em for text, with 8px of padding on both sides)."), "\n", React.createElement(_components.h1, {
    id: "directions",
    style: {
      position: "relative"
    }
  }, "Directions", React.createElement(_components.a, {
    href: "#directions",
    "aria-label": "directions permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Tooltips can appear in many directions relative to an element. Direction is manually controlled. The default is to appear above the element."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<Stack direction=\"column\" spacing={2} className=\"m-6\">\n\t<Stack justifyContent=\"space-between\">\n\t\t<Tooltip el='div' direction='tl' text='Topleft side tooltip'>\n\t\t\t<Button>Top Left</Button>\n\t\t</Tooltip>\n\t\t<Tooltip el='div' text='Top side tooltip'>\n\t\t\t<Button>Top</Button>\n\t\t</Tooltip>\n\t\t<Tooltip el='div' direction='tr' text='Topright side tooltip'>\n\t\t\t<Button>Top Right</Button>\n\t\t</Tooltip>\n\t</Stack>\n\t<Stack justifyContent=\"space-between\">\n\t\t<Tooltip el='div' direction='l' text='Left side tooltip'>\n\t\t\t<Button>Left</Button>\n\t\t</Tooltip>\n\t\t<Tooltip el='div' direction='r' text='Right side tooltip'>\n\t\t\t<Button>Right</Button>\n\t\t</Tooltip>\n\t</Stack>\n\t<Stack justifyContent=\"space-between\">\n\t\t<Tooltip el='div' direction='bl' text='Bottomleft side tooltip'>\n\t\t\t<Button>Bottom Left</Button>\n\t\t</Tooltip>\n\t\t<Tooltip el='div' direction='b' text='Bottom side tooltip'>\n\t\t\t<Button>Bottom</Button>\n\t\t</Tooltip>\n\t\t<Tooltip el='div' direction='br' text='Bottomright side tooltip'>\n\t\t\t<Button>Bottom Right</Button>\n\t\t</Tooltip>\n\t</Stack>\n</Stack>\n")), "\n", React.createElement(_components.h1, {
    id: "with-other-components",
    style: {
      position: "relative"
    }
  }, "With Other Components", React.createElement(_components.a, {
    href: "#with-other-components",
    "aria-label": "with other components permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.h3, {
    id: "with-icons",
    style: {
      position: "relative"
    }
  }, "With icons", React.createElement(_components.a, {
    href: "#with-icons",
    "aria-label": "with icons permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Tooltips should be used when icons are present without visible descriptions."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<ButtonGroup attached className=\"m-b-3\">\n\t<Tooltip el='div' direction='br' text='Bold ⌘ + B'>\n\t\t<Button iconName='format_bold' />\n\t</Tooltip>\n\t<Tooltip el='div' open direction='b' text='Italic ⌘ + I'>\n\t\t<Button iconName='format_italic' />\n\t</Tooltip>\n\t<Tooltip el='div' direction='b' text='Underline ⌘ + U'>\n\t\t<Button iconName='format_underlined' />\n\t</Tooltip>\n\t<Tooltip el='div' direction='b' text='Strikethrough ⌘ + Shift + X'>\n\t\t<Button iconName='format_strikethrough' />\n\t</Tooltip>\n\t<Tooltip el='div' direction='b' text='Align Left ⌘ + Shift + L'>\n\t\t<Button iconName='format_align_left' />\n\t</Tooltip>\n\t<Tooltip el='div' direction='b' text='Align Center ⌘ + Shift + E'>\n\t\t<Button iconName='format_align_center' />\n\t</Tooltip>\n\t<Tooltip el='div' direction='bl' text='Align Right ⌘ + Shift + R'>\n\t\t<Button iconName='format_align_right' />\n\t</Tooltip>\n</ButtonGroup>\n")), "\n", React.createElement(_components.h3, {
    id: "with-an-avatar",
    style: {
      position: "relative"
    }
  }, "With an Avatar", React.createElement(_components.a, {
    href: "#with-an-avatar",
    "aria-label": "with an avatar permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<Tooltip open direction='right' text='Rose Tico'>\n  <Avatar name=\"Rose Tico\"  />\n</Tooltip>\n")), "\n", React.createElement(_components.h3, {
    id: "with-a-progress-bar",
    style: {
      position: "relative"
    }
  }, "With a Progress Bar", React.createElement(_components.a, {
    href: "#with-a-progress-bar",
    "aria-label": "with a progress bar permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<div className=\"m-b-3\">\n\t<Tooltip el='div' open direction='b' text='50% Complete'>\n\t  <ProgressBar progress={50} yellow />\n\t</Tooltip>\n</div>\n")), "\n", React.createElement(_components.h3, {
    id: "with-a-tag",
    style: {
      position: "relative"
    }
  }, "With a Tag", React.createElement(_components.a, {
    href: "#with-a-tag",
    "aria-label": "with a tag permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<div className=\"m-b-3\">\n\t<Tooltip open style={{display: 'inline-block'}} direction='b' text='Nothing to see here'>\n\t  <Tag onClose={() => {}}>Nothing to...</Tag>\n\t</Tooltip>\n</div>\n")), "\n", React.createElement(_components.h3, {
    id: "with-a-text",
    style: {
      position: "relative"
    }
  }, "With a Text", React.createElement(_components.a, {
    href: "#with-a-text",
    "aria-label": "with a text permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<div className=\"m-b-3\">\n\t<Tooltip underline style={{display: 'inline-block'}} direction='b' text='Nothing to see here'>\n\t\t<BodyText onClose={() => {}}>\n\t\t\tNothing to...\n\t\t</BodyText>\n\t</Tooltip>\n</div>\n")), "\n", React.createElement(_components.h1, {
    id: "open-through-a-react-portal",
    style: {
      position: "relative"
    }
  }, "Open through a React Portal", React.createElement(_components.a, {
    href: "#open-through-a-react-portal",
    "aria-label": "open through a react portal permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Tooltips can be opened outside of the DOM hierarchy through a React portal. These ‘portaled’ tooltips:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Won't be clipped by the boundaries of a container, which has ", React.createElement(_components.code, null, "overflow: hidden"), " property set"), "\n", React.createElement(_components.li, null, "Are hoverable, they don't disappear when pointer moves over them, so the text content may be marked and copied"), "\n"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<div className=\"m-b-3\">\n\t<Card style={{overflow: 'hidden'}}>\n\t\t<Tooltip open direction='t' portal text='Tooltip via portal'>\n\t\t\t<a href=\"#show-on-focus\" className=\"m-x-3\"><Icon name=\"report\" size=\"24px\" color=\"gray\" /></a>\n\t\t</Tooltip>\n\t\t<Tooltip open direction='b' text='Regular tooltip'>\n\t\t\t<a href=\"#show-on-focus\" className=\"m-x-3\"><Icon name=\"help\" size=\"24px\" color=\"gray\" /></a>\n\t\t</Tooltip>\n\t\t<Tooltip direction='t' portal width text='Rose Tico\\nid: 0123456789'>\n\t\t\t<a href=\"#show-on-focus\" className=\"m-x-3\"><Icon name=\"account_circle\" size=\"24px\" color=\"gray\" /></a>\n\t\t</Tooltip>\n\t\t<Tooltip direction='b' text='Charging, 80%'>\n\t\t\t<a href=\"#show-on-focus\" className=\"m-x-3\"><Icon name=\"battery_charging_80\" size=\"24px\" color=\"gray\" /></a>\n\t\t</Tooltip>\n\t</Card>\n</div>\n")), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h1, {
    id: "best-practices",
    style: {
      position: "relative"
    }
  }, "Best Practices", React.createElement(_components.a, {
    href: "#best-practices",
    "aria-label": "best practices permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Tooltips should be clear and concise."), "\n", React.createElement(_components.li, null, "Tooltips should appear with icon-only buttons."), "\n", React.createElement(_components.li, null, "Tooltips can be used to provide keyboard shortcuts."), "\n", React.createElement(_components.li, null, "Tooltips should supplement. Essential information should not be behind a component that only appears on hover."), "\n", React.createElement(_components.li, null, "Only one tooltip should be active at any point."), "\n", React.createElement(_components.li, null, "Avoid error or warning messages. Use one of the notification components instead."), "\n", React.createElement(_components.li, null, "Avoid messages longer than two lines in length."), "\n", React.createElement(_components.li, null, "Complex information, interactions, or needing a click state should use the ", React.createElement(_components.strong, null, React.createElement(_components.a, {
    href: "/components/popover"
  }, "Popover")), " component."), "\n"), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h1, {
    id: "related-components",
    style: {
      position: "relative"
    }
  }, "Related Components", React.createElement(_components.a, {
    href: "#related-components",
    "aria-label": "related components permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "For more complex content, use a ", React.createElement(_components.strong, null, React.createElement(_components.a, {
    href: "/components/popover"
  }, "Popover")), " component."), "\n"), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h1, {
    id: "importing",
    style: {
      position: "relative"
    }
  }, "Importing", React.createElement(_components.a, {
    href: "#importing",
    "aria-label": "importing permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "code: true\n---\nimport { Tooltip } from '@servicetitan/design-system';\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
